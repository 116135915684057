import React from 'react';
import { graphql } from 'gatsby';
import Layout from '../components/layout';
import ShareLinks from '../components/share-links';
import formatRichText from '../format-rich-text';

export default ({ data }) => {
  const page = data.contentfulPage;
  const primaryTransitColor = '#000';

  return (
    <Layout
      title={page.title}
      description={page.summary?.childMarkdownRemark?.rawMarkdownBody}
      image={page.heroImage?.fluid?.src}
      mainClass="article-page"
      currentSection="faq"
    >
      <article className="article">
        <div className="article-title">
          <div className="article-title__inner">
            <div>
              <ShareLinks
                url={`https://away.mta.info${page.path}`}
                title={page.title}
              />
            </div>
            <h1>{page.title}</h1>
          </div>
          <div
            className="article-progress"
            style={{ color: primaryTransitColor }}
          />
        </div>
        <div className="article-content">
          <div className="article-content-inner">
            {page.heroImage && (
              <div className="article-hero">
                <img
                  alt={page.heroImage?.title}
                  src={page.heroImage?.fluid?.src}
                />
              </div>
            )}
            {formatRichText(page.body.json)}
          </div>
        </div>
      </article>
      <aside className="sidebar">
        <div className="sidebar-inner">
          {(page.adUnits || []).map(adUnit => (
            <div key={adUnit.id}>
              <a href={adUnit.link} target="_blank" rel="noreferrer">
                <img
                  src={adUnit.asset.file.url}
                  width={adUnit.asset.file.details.image.width}
                  height={adUnit.asset.file.details.image.height}
                  alt={adUnit.asset.title}
                />
              </a>
            </div>
          ))}
        </div>
      </aside>
    </Layout>
  );
};

export const pageQuery = graphql`
  query PageById($id: String!) {
    contentfulPage(id: { eq: $id }) {
      title
      path
      heroImage {
        ... on ContentfulAsset {
          title
          fluid(maxWidth: 1180, background: "rgb:000000") {
            src
          }
        }
      }
      body {
        json
      }
      summary {
        childMarkdownRemark {
          rawMarkdownBody
        }
      }
    }
  }
`;
