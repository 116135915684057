import React from 'react';
import { copyToClipboard } from '../utils';

export default ({ url, title }) => (
  <div className="share-links">
    <a
      className="share-link share-link--facebook"
      href={`https://www.facebook.com/sharer.php?u=${encodeURIComponent(url)}`}
      target="_blank"
      rel="noreferrer"
    >
      Share
    </a>{' '}
    <a
      className="share-link share-link--twitter"
      href={`https://twitter.com/intent/tweet?text=${encodeURIComponent(
        title
      )}&url=${encodeURIComponent(url)}`}
      target="_blank"
      rel="noreferrer"
    >
      Tweet
    </a>{' '}
    <a
      className="share-link share-link--email"
      href={`mailto:?subject=${encodeURIComponent(
        title
      )}&body=${encodeURIComponent(url)}`}
      target="_blank"
      rel="noreferrer"
    >
      Email
    </a>{' '}
    <button
      type="button"
      className="btn--copy"
      onClick={() => copyToClipboard(url)}
    >
      Copy
    </button>
  </div>
);
